import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FormChangesGuard, RoleGuard } from '@depot/@common';
import { DepotCommonModule } from '@depot/@common/depot-common.module';
import { DepotComponentsModule } from '@depot/@components/depot-components.module';
import { PartsEditComponent, PartsGridComponent } from '@depot/parts';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DepotCommonModule,
    DepotComponentsModule,
    RouterModule.forChild([{
      path: '',
      component: PartsGridComponent,
      canActivate: [RoleGuard],
      data: {
        isNav: false,
        text: 'Parts',
        icon: 'fa fa-boxes',
        roles: [RoleGuard.Admin]
      },

    },
    {
      path: ':line/:number',
      component: PartsEditComponent,
      canActivate: [RoleGuard],
      canDeactivate: [FormChangesGuard],
      data: {
        isNav: false,
        title: 'Parts > Edit',
        icon: 'fa fa-boxes',
        roles: [RoleGuard.Admin]
      }
    }
    ])
  ],
  declarations: [
    PartsEditComponent,
    PartsGridComponent,
  ],
  exports: [PartsEditComponent, PartsGridComponent]
})
export class PartsModule { }
