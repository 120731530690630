import { DataSource } from '@angular/cdk/table';

import { IPartsSearch, PartRepositoryService } from '@depot/@data';
import { IPagedResults, IPart } from '@depot/custom';

import { BehaviorSubject, finalize, Subject } from 'rxjs';

export class PartsGridDataSource extends DataSource<IPart> {
  public isLoading$ = new BehaviorSubject(false);
  public totalRows$ = new BehaviorSubject(0);
  public get filterName() { return 'parts_grid'; }
  public columns = [
    'edit',
    'line',
    'number',
    'description',
    'price',
    'inventoryWi',
    'inventoryTn',
    'inventoryCo',
    'partImages'
  ];
  private data$ = new Subject<IPart[]>();
  constructor(private partRepo: PartRepositoryService) {
    super();

  }
  connect() {
    return this.data$;
  }

  public loadData(filters: IPartsSearch) {

    this.isLoading$.next(true);
    this.partRepo.searchParts(filters)
      .pipe(
        // catchError(err => of({ items: [] })),
        finalize(() => this.isLoading$.next(false))
      ).subscribe((x: IPagedResults<IPart>) => {
        this.data$.next(x.items);
        this.totalRows$.next(x.totalCount);
      });
  }

  disconnect(): void {
    this.data$.complete();
    this.isLoading$.next(false);
    this.isLoading$.complete();
    this.totalRows$.complete();
    // this.depotContext.close();
  }

}
