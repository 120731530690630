<div class="content mat-elevation-z8 ">
  <!--  -->
  <mat-expansion-panel #searchPanel>
    <mat-expansion-panel-header>
      <mat-panel-title class="filter-panel-title">
        Filters
        <button mat-button
                color="primary"
                (click)="loadData($event)">Search</button>
        <button mat-button
                color="primary"
                (click)="resetFilters($event)">Reset</button>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent
                 *ngIf="(cachedFilters$ | async) as cache">

      <mat-form-field>
        <mat-label>Number</mat-label>
        <input matInput
               [(ngModel)]="cache.number">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Product Lines</mat-label>
        <mat-select [(ngModel)]="cache.line">
          <mat-option value=""> - Any - </mat-option>
          <mat-option *ngFor="let line of (productLines$ | async)"
                      [value]="line.lineCode">
            {{line.lineCode}} - {{line.internalName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Filter Type</mat-label>
        <mat-select [(ngModel)]="cache.selectedFilter">
          <mat-option *ngFor="let item of filterTypes | keyvalue"
                      [value]="item.key">
            {{item.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </ng-template>
  </mat-expansion-panel>
  <mat-table [dataSource]="dataSource"
             matSort
             [matSortActive]="(cachedFilters$ | async).orderBy?.split(' ')[0]"
             [matSortDirection]="(cachedFilters$ | async).orderBy?.split(' ')[1]"
             (matSortChange)="sortFilters()">

    <ng-container matColumnDef="edit">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef></mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        <a mat-icon-button
           matTooltip="Edit"
           [routerLink]="[row.line,row.number]">
          <span class="fa fa-edit"></span>
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="line">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Line</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.line}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="number">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Number</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.number}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Description</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.description}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="price">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Price</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.priceFormatted}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="inventoryWi">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>WI</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.inventoryWi}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="inventoryTn">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>TN</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.inventoryTn}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="inventoryCo">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>CO</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.inventoryCo}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="partImages">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef>Images</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.partImages?.length || row.partImagePartials?.length}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="dataSource.columns; sticky: true"
                    class="table-header-primary"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataSource.columns;"></mat-row>
    <div class="no-data-row"
         *matNoDataRow>
      No parts found
    </div>

  </mat-table>

  <mat-paginator [disabled]="dataSource?.isLoading$ | async"
                 (page)="loadData()"
                 [pageSize]="(cachedFilters$ | async)?.takeCount"
                 [pageSizeOptions]="[25, 50, 250, 500]">
  </mat-paginator>
</div>
