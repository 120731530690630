import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { HelperService, ImageService } from '@depot/@common';
import { CameraDialogComponent, ImageEditDialogComponent } from '@depot/@components';
import { OrderRepositoryService, PartRepositoryService, PriceBookRepositoryService } from '@depot/@data';
import { IClientError, IOrder, IPartData, IPartImage, IPriceBookLine, IProductLine } from '@depot/custom';
import { environment } from '@env';

import { BehaviorSubject, catchError, finalize, first, map, Observable, of } from 'rxjs';

import { sortBy, unique } from 'underscore';

interface IPartDataImageForm {
  id: number;
  fileStreamId: string;
  auditRowId: number | null;
  partLine: string;
  partNumber: string;
  isExternal: boolean;
  sortOrder: number;
  fileStream: number[];
  imagePath: string;
}
interface IPartDataForm {
  id: FormControl<number>;
  partLine: FormControl<string>;
  partNumber: FormControl<string>;
  description: FormControl<string>;
  partDescription: FormControl<string>;
  make: FormControl<string>;
  weightIbs: FormControl<number>;
  lengthIn: FormControl<number>;
  widthIn: FormControl<number>;
  heightIn: FormControl<number>;
  innerDiameterIn: FormControl<number>;
  outerDiameterIn: FormControl<number>;
  tireSize: FormControl<number>;
  hasServiceLife: FormControl<boolean>;
  inventoryWi: FormControl<number>;
  inventoryTn: FormControl<number>;
  inventoryCo: FormControl<number>;
  partImages$: FormControl<BehaviorSubject<IPartDataImageForm[]>>;
}


@Component({
  selector: 'depot-parts-edit',
  templateUrl: './parts-edit.component.html',
  styleUrls: ['./parts-edit.component.scss']
})
export class PartsEditComponent implements OnInit {

  public form: FormGroup<IPartDataForm>;
  // private line: string;
  // private number: string;
  public productLines$: Observable<IProductLine[]>;
  public partData$: BehaviorSubject<IPartData>;
  public globalErrors$ = new BehaviorSubject<string[]>([]);
  public priceBookItems$ = new BehaviorSubject<IPriceBookLine[]>([]);
  public ordersDataSource = new MatTableDataSource<IOrder>();
  @ViewChild(MatSort) ordersSort: MatSort;
  @ViewChild(MatPaginator) ordersPaginator: MatPaginator;

  constructor(
    private orderRepo: OrderRepositoryService,
    private partRepo: PartRepositoryService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private helper: HelperService,
    private file: ImageService,
    private fb: FormBuilder,
    private priceBookRepo: PriceBookRepositoryService,
  ) {
    this.helper.IsGlobalBar$.set(true);

  }


  ngOnInit() {

    this.productLines$ = this.partRepo.getProductLines()
      .pipe(
        map(x => {
          const productLines = x.filter(y => y.internalName !== null);
          const arr = unique(productLines, item => item.lineCode);
          return sortBy(arr, item => item.internalSort);
        })
      );

    const params = this.activatedRoute.snapshot.params;
    this.priceBookRepo.getPriceBookLine(params['line'], params['number'], false, true, false)
      .pipe(catchError(x => of(<IPriceBookLine[]>[]))).subscribe(priceBook => {
        const sortedData = sortBy(priceBook, x => x.date).reverse();
        this.priceBookItems$.next(sortedData);
      });

    this.orderRepo.getOrderByPart(params['line'], params['number'])
      .pipe(catchError(x => of(<IOrder[]>[]))).subscribe(orders => {
        const sortedData = sortBy(orders, x => x.createdDate).reverse();
        this.ordersDataSource.sort = this.ordersSort;
        this.ordersDataSource.paginator = this.ordersPaginator;
        this.ordersDataSource.data = sortedData;
      });

    this.partRepo.getPartData(params['line'], params['number'])
      .pipe(finalize(() => {
        this.helper.IsGlobalBar$.set(false);
      }))
      .subscribe(partData => {
        if (!partData) {
          partData = <any>{};
        }
        this.partData$ = new BehaviorSubject(partData);
        this.form = this.fb.group({
          id: [partData.id, []],
          partLine: [partData.partLine, []],
          partNumber: [partData.partNumber, []],
          description: [partData.description, []],
          partDescription: [partData.part.description, []],
          make: [partData.make, [Validators.required]],
          weightIbs: [partData.weightIbs, [Validators.min(0)]],
          lengthIn: [partData.lengthIn, [Validators.min(0)]],
          widthIn: [partData.widthIn, [Validators.min(0)]],
          heightIn: [partData.heightIn, [Validators.min(0)]],
          innerDiameterIn: [partData.innerDiameterIn, [Validators.min(0)]],
          outerDiameterIn: [partData.outerDiameterIn, [Validators.min(0)]],
          tireSize: [partData.tireSize, [Validators.min(0)]],
          hasServiceLife: [partData.hasServiceLife, []],
          inventoryWi: [partData.part?.inventoryWi ?? 0],
          inventoryTn: [partData.part?.inventoryTn ?? 0],
          inventoryCo: [partData.part?.inventoryCo ?? 0],
          partImages$: [new BehaviorSubject((partData.partImagePartials ?? []).map(img => this.mapFormImage(img)))],
        });
      });

  }



  public async saveForm() {
    this.globalErrors$.next([]);
    if (!this.helper.validateForm(this.form)) {
      this.helper.showMessage('Form is invalid');
      return;
    }
    this.helper.IsGlobalSpinner$.set(true);
    const input = this.form.getRawValue();
    input.partImages$ = null;
    this.partRepo.savePartData(<any>input)
      .pipe(
        catchError((error: IClientError) => {
          this.helper.mapErrors(error, null, this.form, this.globalErrors$);
          this.helper.logger.error('Error saving part', error);
          return of();
        }),
        finalize(() => this.helper.IsGlobalSpinner$.set(false))
      )
      .subscribe(x => {
        this.helper.showMessage('Changes saved successful', 'success');

      });
  }

  private mapFormImage(img: IPartImage): IPartDataImageForm {
    return {
      id: img.id,
      fileStreamId: img.fileStreamId,
      auditRowId: img.auditRowId,
      partLine: img.partLine,
      partNumber: img.partNumber,
      isExternal: img.isExternal,
      sortOrder: img.sortOrder || 0,
      fileStream: img.fileStream,
      imagePath: environment.get_endpoint('part/partimage/' + img.fileStreamId),
    };
  }

  public onCameraDialog() {
    this.dialog.open(CameraDialogComponent, {
      data: {},
      disableClose: true,
      maxHeight: '100vh'
    }).afterClosed()
      .pipe(first())
      .subscribe(async (blob: string) => {
        if (blob) {
          await this.addImage(blob);
        }
      });
  }

  public async onImageSelect(file: File) {

    const path = await this.file.blobToBase64Async(file);
    this.addImage(path);
  }

  private async addImage(imageUrl: string) {
    // const file = event.target.files[0];
    const part = this.partData$.getValue();
    const data: IPartImage = {
      partLine: part.partLine,
      partNumber: part.partNumber,
      sortOrder: 0,
      isExternal: false,
      fileStream: null,
      id: 0,
    };
    this.dialog.open(ImageEditDialogComponent, {
      data: { partImage: data, imagePath: imageUrl },
      disableClose: true,
      maxHeight: '100vh'
    }).afterClosed()
      .pipe(first())
      .subscribe(newPartImage => {
        if (newPartImage) {
          this.partRepo.saveImage(newPartImage)
            .pipe(catchError(() => {
              this.helper.showMessage('Error saving image', 'error');
              return of(null);
            }))
            .subscribe(savedPartImage => {
              const images = this.form.get('partImages$').value.value;
              images.push(this.mapFormImage(savedPartImage));

              this.form.get('partImages$').value.next(images);

            });

          // this.partData$.getValue().partImages.push(newPartImage);
        }
        // this.partData$.value.partImages.find(x => x.id === newPartImage.id).fileStream = newPartImage.fileStream;
      });

  }

  public onOrderFilterChange(event: Event) {
    this.ordersDataSource.filter = (event.target as HTMLInputElement).value;
  }

}
