<div>
  <div class="content">
    <mat-error *ngFor="let err of globalErrors$ | async">&bull; {{err}}</mat-error>
  </div>
  <div class="content tw-flex tw-flex-row">
    <div class="tw-flex tw-flex-wrap tw-place-content-start tw-items-start tw-gap-1">

      <form *ngIf="form"
            (ngSubmit)="saveForm()"
            [formGroup]="form">

        <mat-form-field *ngIf="(productLines$ | async) as productLines">
          <mat-label>Product Lines</mat-label>
          <mat-select formControlName="partLine"
                      readonly>
            <mat-option *ngFor="let line of productLines"
                        [value]="line.lineCode">
              {{line.lineCode}} - {{line.internalName}}
            </mat-option>
          </mat-select>
          <mat-error [depot-form-error]="form"
                     errorControlName="partLine">
          </mat-error>
        </mat-form-field>


        <mat-form-field class="readonly">
          <mat-label>Number</mat-label>
          <input matInput
                 formControlName="partNumber"
                 required
                 readonly
                 type="text" />
          <mat-error [depot-form-error]="form"
                     errorControlName="partNumber">
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Make</mat-label>
          <input matInput
                 formControlName="make"
                 type="text" />
          <mat-error [depot-form-error]="form"
                     errorControlName="make">
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Weight (Ibs.)</mat-label>
          <input matInput
                 formControlName="weightIbs"
                 type="number" />
          <mat-error [depot-form-error]="form"
                     errorControlName="weightIbs">
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Length (In.)</mat-label>
          <input matInput
                 formControlName="lengthIn"
                 type="number" />
          <mat-error [depot-form-error]="form"
                     errorControlName="lengthIn">
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Weight (In.)</mat-label>
          <input matInput
                 formControlName="widthIn"
                 type="number" />
          <mat-error [depot-form-error]="form"
                     errorControlName="widthIn">
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Height (In.)</mat-label>
          <input matInput
                 formControlName="heightIn"
                 type="number" />
          <mat-error [depot-form-error]="form"
                     errorControlName="heightIn">
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Inner Diameter (In.)</mat-label>
          <input matInput
                 formControlName="innerDiameterIn"
                 type="number" />

          <mat-error [depot-form-error]="form"
                     errorControlName="innerDiameterIn">
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Outer Diameter (In.)</mat-label>
          <input matInput
                 formControlName="outerDiameterIn"
                 type="number" />

          <mat-error [depot-form-error]="form"
                     errorControlName="outerDiameterIn">
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Tire Size</mat-label>
          <input matInput
                 formControlName="tireSize"
                 type="number" />

          <mat-error [depot-form-error]="form"
                     errorControlName="tireSize">
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Description</mat-label>
          <input matInput
                 formControlName="description"
                 type="text" />
          <mat-hint>{{form.get('partDescription').value}}</mat-hint>
          <mat-error [depot-form-error]="form"
                     errorControlName="description">
          </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="hasServiceLife">
          Has Service Life
        </mat-checkbox>

        <div class="tw-w-full"></div>

        <mat-form-field class="readonly">
          <mat-label>Inventory WI</mat-label>
          <input matInput
                 formControlName="inventoryWi"
                 type="number"
                 readonly />

          <mat-error [depot-form-error]="form"
                     errorControlName="inventoryWi">
          </mat-error>
        </mat-form-field>

        <mat-form-field class="readonly">
          <mat-label>Inventory TN</mat-label>
          <input matInput
                 formControlName="inventoryTn"
                 type="number"
                 readonly />
          <mat-error [depot-form-error]="form"
                     errorControlName="inventoryTn">
          </mat-error>
        </mat-form-field>

        <mat-form-field class="readonly">
          <mat-label>Inventory CO</mat-label>
          <input matInput
                 formControlName="inventoryCo"
                 type="number"
                 readonly />
          <mat-error [depot-form-error]="form"
                     errorControlName="inventoryCo">
          </mat-error>
        </mat-form-field>

      </form>

      <div class="tw-w-full tw-pr-4">
        <mat-table [dataSource]="priceBookItems$">
          <ng-container matColumnDef="make">
            <mat-header-cell *matHeaderCellDef>Make</mat-header-cell>
            <mat-cell mat-cell
                      *matCellDef="let row">{{row.make}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="manufacturerNumber">
            <mat-header-cell *matHeaderCellDef>Manufacturer Number</mat-header-cell>
            <mat-cell mat-cell
                      *matCellDef="let row">{{row.manufacturerNumber}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
            <mat-cell mat-cell
                      *matCellDef="let row">{{row.description}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="weightIbs">
            <mat-header-cell *matHeaderCellDef>Weight</mat-header-cell>
            <mat-cell mat-cell
                      *matCellDef="let row">{{row.weightIbs}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="listPrice">
            <mat-header-cell *matHeaderCellDef>List Price</mat-header-cell>
            <mat-cell mat-cell
                      *matCellDef="let row">{{row.listPrice | currency}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="dealerPrice">
            <mat-header-cell *matHeaderCellDef>Dealer Net</mat-header-cell>
            <mat-cell mat-cell
                      *matCellDef="let row">{{row.dealerPrice | currency}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="year">
            <mat-header-cell *matHeaderCellDef>Year</mat-header-cell>
            <mat-cell mat-cell
                      *matCellDef="let row">{{row.year}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef=" ['make', 'manufacturerNumber', 'description', 'weightIbs', 'listPrice', 'dealerPrice', 'year']; sticky: false"
                          class="table-header-primary">
          </mat-header-row>

          <mat-row
                   *matRowDef="let row; let i = index; columns:  ['make', 'manufacturerNumber', 'description', 'weightIbs', 'listPrice', 'dealerPrice', 'year'];">
          </mat-row>

          <div class="no-data-row"
               *matNoDataRow>
            No price books found
          </div>
        </mat-table>

        <mat-form-field class="dp-no-subset tw-w-1/2">
          <mat-label>Filter Orders</mat-label>
          <input matInput
                 (keyup)="onOrderFilterChange($event)"
                 type="text" />
        </mat-form-field>
        <mat-table [dataSource]="ordersDataSource"
                   matSort>
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header>Order Id</mat-header-cell>
            <mat-cell mat-cell
                      *matCellDef="let row">{{row.id}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="dealerNumber">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header>Dealer Number</mat-header-cell>
            <mat-cell mat-cell
                      *matCellDef="let row">{{row.dealerNumber}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="dealerName">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header>Dealer Name</mat-header-cell>
            <mat-cell mat-cell
                      *matCellDef="let row">{{row.dealerName}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="createdDate">
            <mat-header-cell *matHeaderCellDef
                             mat-sort-header>Created Date</mat-header-cell>
            <mat-cell mat-cell
                      *matCellDef="let row">{{row.createdDate | utcdate:'yyyy-MM-dd'}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef=" ['id', 'dealerNumber', 'dealerName', 'createdDate']; sticky: true"
                          class="table-header-primary">
          </mat-header-row>

          <mat-row *matRowDef="let row; let i = index; columns:  ['id','dealerNumber', 'dealerName', 'createdDate'];">
          </mat-row>
          <div class="no-data-row"
               *matNoDataRow>
            No orders found
          </div>

        </mat-table>
        <mat-paginator #ordersPaginator
                       [pageSize]="10"
                       [pageSizeOptions]="[10, 25, 50, 250, 500]">
        </mat-paginator>
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-flex-wrap tw-flex-grow tw-flex-shrink tw-basis-full tw-items-stretch tw-justify-center "
         style="max-width: 40%;">
      @if(form){
      <depot-image-row [ImageArray]="form.value.partImages$"
                       [ShowEditOverride]="true"
                       [SortImages]="true"></depot-image-row>
      }
    </div>
  </div>

  <div class="dp-top-fab-container">

    <button type="submit"
            mat-mini-fab
            matTooltip="Save Part"
            matTooltipPosition="below"
            color="accent">
      <span class="fa fa-save"></span>
    </button>
    <button (click)="onCameraDialog()"
            type="button"
            mat-mini-fab
            matTooltip="Take Picture"
            matTooltipPosition="below"
            color="accent">
      <span class="fa fa-camera"></span>
    </button>
    <button (click)="uploadInput.click()"
            type="button"
            mat-mini-fab
            matTooltip="Upload Image"
            matTooltipPosition="below"
            color="accent">
      <span class="fa fa-upload"></span>
    </button>
  </div>

  <input type="file"
         #uploadInput
         style="display: none"
         (change)="onImageSelect($event.target.files[0])" />
</div>
